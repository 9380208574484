import React, { Component } from "react";
import {
  Autoplay,
  EffectCreative,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import Layout from "../components/Layout";
import BlogContainer from "../components/BlogContainer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Best Architecture Design Company | Top Architects in Surat - ZED
          </title>
          <meta
            name="title"
            content="Best Architecture Design Company | Top Architects in Surat - ZED"
          ></meta>
          <meta
            name="keywords"
            content="architecture design company in Surat, Top architects in Surat, best architect in Surat"
          ></meta>
          <meta
            name="description"
            content="Finding best architecture design company in Surat? Mr. Zaid and Team, top and best architect based in Surat offering an exceptional service on architecture design."
          ></meta>
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>ABOUT US</span>
                  <h1 className="text-center">
                    Driven by passion, proven by integrity
                  </h1>
                </div>
                <Swiper className="inner__banner__slider">
                  <SwiperSlide>
                    <img
                      src="img/about__banner1.png"
                      className="w-100"
                      alt="Banner"
                    />
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <img src="img/about__banner2.png" className="w-100" alt="Banner" />
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="our__mission-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4">
                <div className="our__mission-img">
                  <img src="img/our_mission.png" alt="Our Mission" />
                </div>
              </div>
              <div className="col-lg-6 col-md-8">
                <div className="our__mission-text">
                  <h2>
                    Our purpose is to design enduring creations for clients
                  </h2>
                  <p>
                    At ZED, we focus on creating elegant spaces ranging from
                    yesteryear's traditional, mid- century and contemporary
                    architectural designs tailored intricately to our clients'
                    needs.
                    <br />
                    Started 7 years ago, in no time ZED is now highly in demand
                    because of our expertise, artistry and competency.
                    <br />
                    ZED works under the stewardship of Mr. Zaid Shaikh. It is
                    his vision to become the top brand of Surat providing
                    remarkable architectural projects and setting new benchmarks
                    in the industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our__team-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>OUR TEAM!</span>
                  <h2>Experienced &amp; Passionate</h2>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            className="team__slider"
            modules={[Navigation]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                freeMode: true,
              },
              600: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/zaid.png" alt="Zaid Shaikh" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Zaid Shaikh</h4>
                  <p className="mb-0">Managing Director</p>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/murtaza.jpg" alt="Uvesh" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Murtaza Tarwala</h4>
                  <p className="mb-0">Planning</p>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/sufiyan.png" alt="Sufian" />
                </div>
                <div className="team__member-des text-center">
                  <h4>AR.Sufiyan</h4>
                  <p className="mb-0">Head of Planning Department</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/julli.png" alt="July" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Julie Luthra</h4>
                  <p className="mb-0">Head of Interior Department</p>
                </div>
              </div>
            </SwiperSlide>
           


            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/hely.png" alt="Helly" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Hely Patel</h4>
                  <p className="mb-0"> Senior Interior Designer</p>
                </div>
              </div>
            </SwiperSlide>
        
            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/yashvi.png" alt="yashvi" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Yashvi Shah</h4>
                  <p className="mb-0">Junior Interior Designer</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/mubasir.png" alt="parth" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Mubhasir shaikh</h4>
                  {/* <p className="mb-0"> Senior Interior Designer</p> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/riddhi.png" alt="riddhi" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Riddhi Majithiya</h4>
                  {/* <p className="mb-0"> Senior Interior Designer</p> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/parth.png" alt="parth" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Parth Prajapati</h4>
                  {/* <p className="mb-0"> Senior Interior Designer</p> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="team__member">
                <div className="team__member-img">
                  <img src="img/team/hussin.png" alt="parth" />
                </div>
                <div className="team__member-des text-center">
                  <h4>Hussain khan</h4>
                  {/* <p className="mb-0"> Senior Interior Designer</p> */}
                </div>
              </div>
            </SwiperSlide>


            <div className="swiper-button swiper-button-next"></div>
            <div className="swiper-button swiper-button-prev"></div>
          </Swiper>
        </section>
        <section className="our__qualities-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>
                    ZED- The fastest-growing architecture and designing brand
                  </span>
                  <h2>
                    At ZED commitment, timeliness and client satisfaction, are
                    the watchwords that have always helped us to build a stature
                    like architecture brand.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="our__quality">
                  <h4>01</h4>
                  <h4>Innovative</h4>
                  <p className="mb-0">
                    We believe in innovation! At ZED we highly believe in
                    executing innovative projects harmonized with traditional
                    values. Our years of expertise and extensive research
                    enables us to design and develop each project uniquely and
                    innovatively.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="our__quality">
                  <h4>02</h4>
                  <h4>Sustainable</h4>
                  <p className="mb-0">
                    At ZED, protecting Mother Nature is our priority! We
                    constantly look after building sustainable architecture, by
                    consuming less energy while being congruous with the
                    surrounding nature.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="our__quality">
                  <h4>03</h4>
                  <h4>Operative</h4>
                  <p className="mb-0">
                    We have keen eyes on our operational and functional skills.
                    Our designs are customized as per the needs of our customers
                    and we use our years of experience to awestruck them with
                    our impactful architecture.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="our__quality">
                  <h4>04</h4>
                  <h4>Artistic</h4>
                  <p className="mb-0">
                    At ZED, we are equipped with an artistic team that makes
                    innovative and impactful designs for any given project. From
                    residential to commercial, we create beautiful, functional
                    spaces that meet our clients' needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="our__quality">
                  <h4>05</h4>
                  <h4>Dynamic</h4>
                  <p className="mb-0">
                    Within our short history, ZED has influenced thousands of
                    lives by building more than 50k square feet of architecture.
                    We mindfully design and develop residential buildings,
                    commercial buildings and individual residents and offices
                    that exude elegance, luxury and finesse on their facade.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            className="stats__slider"
            loop={true}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
          >
            <SwiperSlide>
              <div className="stats__slider-item">
                <h2>
                  8+ <span>Years of Experience</span>
                </h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="stats__slider-item">
                <h2>
                  42 <span>Happy Customers</span>
                </h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="stats__slider-item">
                <h2>
                  147 <span>Properties Built</span>
                </h2>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="stats__slider-item">
                <h2 className="d-block">
                  600K+ <span>Sq. area Built</span>
                </h2>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
        <section className="locations-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4">
                <div className="location__item">
                  <h2>Meet us here!</h2>
                  <Link to="/contact" className="btn">
                    Locate Our Offices{" "}
                    <i className="far fa-long-arrow-alt-right ml-2"></i>
                  </Link>
                </div>
              </div>
              {/*<div className="col-lg-3 col-md-4">
                <div className="location__item">
                  <h4>Begampura<br/>Branch</h4>
                  <p className="mb-0">4/75, Saifee Residency, Malifaliya Rd, near Maskati Hospital, Zampa Bazaar, Begampura, Surat, Gujarat 395003 &nbsp;&nbsp;M : 8298292410</p>
                </div>
              </div>*/}
              <div className="col-lg-6 col-md-4">
                <div className="location__item">
                  <h4>
                    Vesu
                    <br />
                    Branch
                  </h4>
                  <p className="mb-0">
                    A - 511-512, Solarium Business center, VIP Road, Vesu,
                    Surat, Gujarat 395007 &nbsp;&nbsp;M : 8298292410
                  </p>
                </div>
              </div>
              <div class="locations__btn">
                <Link to="/contact" className="btn text-uppercase">
                  Locate our offices{" "}
                  <i className="far fa-long-arrow-alt-right ml-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="our_office-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="our_office__title">
                  <h4>A glimpse of our Office</h4>
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            className="our_office__slider"
            loop={true}
            modules={[EffectFade, EffectCreative, Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
              },
              next: {
                shadow: true,
                translate: ["100%", 0, 0],
              },
            }}
          >
            <SwiperSlide>
              <img src="img/our_office1.jpg" className="w-100" alt="Office1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/our_office2.jpg" className="w-100" alt="Office2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/our_office3.jpg" className="w-100" alt="Office1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/our_office4.jpg" className="w-100" alt="Office2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/our_office5.jpg" className="w-100" alt="Office1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/our_office6.jpg" className="w-100" alt="Office2" />
            </SwiperSlide>
          </Swiper>
        </section>
        <section className="blogs-block">
          <BlogContainer />
        </section>
      </Layout>
    );
  }
}
