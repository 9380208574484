import React, { Component } from 'react'
import Layout from "../components/Layout"
import { getAllPosts, getAllPostCategories } from "../apiFunctions"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Autoplay, EffectCreative, EffectFade } from 'swiper'
import { Link } from "react-router-dom";
import { IMG_URI } from '../config/api'
import { Helmet } from 'react-helmet'
import { XBlock, XMasonry } from 'react-xmasonry'
import BlogContainer from '../components/BlogContainer'

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>ZED | Awards</title>
          <meta name="title" content="ZED | Awards"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>       
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>Awards</span>
                  <h1 className="text-center">We are Voted One of the Top Studio in India</h1>
                </div>
                <Swiper className="inner__banner__slider"
                  loop={false}
                  modules={[EffectFade, EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: ["-20%", 0, -1]
                    },
                    next: {
                      shadow: true,
                      translate: ["100%", 0, 0]
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/img/blog_banner.png" className="w-100" alt="Blog Banner" />
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <img src="/img/blog_banner.jpg" className="w-100" alt="Banner" />
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="awards__tagline--block py-5 my-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="award__tagline">
                            <p className="mb-0">//our strategy to win awzrds discription////////Living in rural communities face unique challenges when it comes to access to quality healthcare. Due to the closure of many rural healthcare facilities and increased pressure to mitigate disease spread, design innovation of new and emerging rural healthcare models plays an even more important role in providing safe access to healthczare.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="awards__list--block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-6">
                        <div className="award__banner">
                            <img src="./img/award_banner_1.jpg" alt="Award" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="award__details">
                            <h2>Architecture and Interior Design  Excellence Award</h2>
                            <p>//Award discriptiopn goes here// dummy text followed Zaid Excel Design acquired this project from an extremely distinguished group - Raj Group. They started the operations before the lockdown began last year in 2020. They had finalized the project in November 2020 & ever since the project is underway with on-site work.Zaid Excel Design acquired this project from an extremely distinguished group - Raj Group. //Award discriptiopn goes here//</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7 col-md-6">
                        <div className="award__banner">
                            <img src="./img/award_banner_1.jpg" alt="Award" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="award__details">
                            <h2>Indian achievers Award</h2>
                            <p>//Award discriptiopn goes here// dummy text followed Zaid Excel Design acquired this project from an extremely distinguished group - Raj Group. They started the operations before the lockdown began last year in 2020. They had finalized the project in November 2020 & ever since the project is underway with on-site work.Zaid Excel Design acquired this project from an cquired this project from an extremely distinguished group - Raj Group. //Award discriptiopn goes here//</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="events__gallery--block">
            <div className="container">
                <h2 className="text-uppercase">EVENT GALLERY</h2>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="events__gallery--item">
                            <img src="./img/gallery_img_1.jpg" alt="Gallery" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="events__gallery--item">
                            <img src="./img/gallery_img_2.jpg" alt="Gallery" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="events__gallery--item">
                            <img src="./img/gallery_img_3.jpg" alt="Gallery" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
<section className="blogs-block">
          <BlogContainer />
        </section>
      
      </Layout>
    )
  }
}
