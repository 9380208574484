import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";

function Header() {
  const [menuOpened, setMenuOpened] = React.useState(false);
  const [fullScreenMenuOpened, setFullScreenMenuOpened] = React.useState(false);

  React.useEffect(() => {
    $(".header__menu--icon, .header__full--close").click(function () {
      $(".header__full--menu").slideToggle();
    });

    $(window).scroll(function () {
      var sticky = $(".header__main"),
        scroll = $(window).scrollTop();

      if (scroll >= 80) {
        sticky.addClass("fixed");
      } else {
        sticky.removeClass("fixed");
      }
    });

    var back_to_top = $(".bottom__to__top");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        back_to_top.addClass("show");
      } else {
        back_to_top.removeClass("show");
      }
    });

    back_to_top.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }, []);

  return (
    <header>
      <div className="header__main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="header__logo">
                <Link to="/">
                  <img src="/img/logo.svg" alt="zaid" />
                </Link>
              </div>
              <div className="header__logo for__mobile">
                <Link to="/">
                  <img src="/img/logo_mobile.svg" alt="zaid" />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 menu__column">
              <div
                className={`mobile_menu ${menuOpened ? "open" : ""}`}
                onClick={() => setMenuOpened(!menuOpened)}
              >
                <img src="/img/menu_line.svg" alt="Menu" />
              </div>
              <div className={`header__menu ${menuOpened ? "open" : ""}`}>
                <div className="d-none d-md-none d-lg-block">
                  <ul className="nav align-items-center justify-content-end">
                    <li className="nav-item">
                      <Link className="nav-link" to="/portfolio">
                        WORK
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/blog">
                        BLOG
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/process">
                        PROCESS
                      </Link>
                    </li>

                    <li className="nav-item">
                      {/* <a className="btn" href="https://webadmin.zaidexceldesign.com/client-login" target="_blank">LOGIN</a> */}
                      <a
                        className="btn"
                        href="https://webclient.zaidexceldesign.com"
                        target="_blank"
                      >
                        LOGIN
                      </a>
                    </li>
                    <li
                      className={`nav-item header__menu--icon`}
                      onClick={() =>
                        setFullScreenMenuOpened(!fullScreenMenuOpened)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <img src="/img/menu_line.svg" alt="Menu" />
                    </li>
                  </ul>
                </div>
                <ul className="mobile__list list-unstyled m-0 d-none d-md-block d-lg-none">
                  <li>
                    <Link className="text-white" to="/portfolio">
                      Work
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/about">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/process">
                      Our Process
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/service">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/blog">
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" to="/contact">
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      className="btn"
                      href="https://webadmin.zaidexceldesign.com/client-login"
                      target="_blank"
                    >
                      LOGIN
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header__full--menu ${fullScreenMenuOpened ? "open" : ""}`}
      >
        <div className="header__full--head d-flex align-items-center justify-content-between">
          <div className="header__full--log">
            <img
              src="/img/logo-white.svg"
              alt="Zaid"
              style={{ maxWidth: "150px" }}
            />
          </div>
          <div
            className="header__full--close d-flex align-items-center justify-content-center rounded-circle"
            onClick={() => setFullScreenMenuOpened(!fullScreenMenuOpened)}
          >
            <img src="/img/close.svg" alt="Close" />
          </div>
        </div>
        <div className="header__full--body row no-gutters">
          <div className="full__header--col col-md-6 d-flex flex-column justify-content-between">
            <div className="header__full--email">
              <p className="mb-0 text-white">
                Start a conversation{" "}
                <Link to="#">info@zaidexceldesign.com</Link>
              </p>
            </div>
            <div className="header__full--social">
              <h4 className="text-white">Catch us here!</h4>
              <ul className="list-unstyled m-0 d-flex flex-wrap align-items-center">
                <li>
                  <a
                    className="text-white"
                    href="https://www.linkedin.com/company/zaid-excel-design-official/"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    className="text-white"
                    href="https://instagram.com/zaidexceldesign/"
                    target="_blank"
                  >
                    Intagram
                  </a>
                </li>
                <li>
                  <a
                    className="text-white"
                    href="https://x.com/ZaidExcelDesign?s=20"
                    target="_blank"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    className="text-white"
                    href="https://facebook.com/zaidexceldesign"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    className="text-white"
                    href="https://www.youtube.com/@zaidexceldesign"
                    target="_blank"
                  >
                    Youtube
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="full__header--col col-md-6">
            <ul className="header__full--links list-unstyled m-0">
              <li>
                <Link className="text-white" to="/portfolio">
                  Work
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/about">
                  About us
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/process">
                  Our Process
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/service">
                  Services
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/blog">
                  Blogs
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
