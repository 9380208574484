import React, { Component } from 'react'
import { getAllWorks } from "../apiFunctions"
import Layout from "../components/Layout"
import { Link } from 'react-router-dom'
import { IMG_URI } from '../config/api'
import { PORTFOLIO_COUNT } from '../config/index'
import { Helmet } from 'react-helmet'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import ReCAPTCHA from "react-google-recaptcha";
import { getLeads } from "../apiFunctions"
import { useNavigate } from 'react-router-dom';

export default class LandingPage extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
      service: '',
      name: '',
      phone: '',
      email: '',
      captcha: '',
      companyName: '',
      city: '',
      projectDescription: '',
      errorService: '',
      errorName: '',
      errorPhone: '',
      errorCaptcha: '',
      errorEmail: '',
      errorCompanyName: '',
       errorCity: '',
      errorProjectDescription: '',
      showThankYou: false,
      showError: false,
      showThankYou1: false,
      showError1: false,
       limit: 3,
      data: [],
      showAll: false
    }
  }
  componentDidMount() {
      
    window.scrollTo(0, 0);
    getAllWorks().then(result => {
      this.setState({ data: result.data })
    })
  }

  handleLimit() {
    if (this.state.limit < this.state.data.length) {
      this.setState({ limit: this.state.limit + PORTFOLIO_COUNT })
    }
    if (this.state.limit >= this.state.data.length) {
      this.setState({ limit: this.state.data.length })
    }
  }
  handleClick(value, key) {
    this.setState({ [key]: value })
  }

handleChange = value => {
    this.setState({ captcha: value });
    
  };

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      errorName: '',
      errorPhone: '',
      errorCaptcha: '',
      errorEmail: '',
      errorCompanyName: '',
      errorCity: '',
      errorProjectDescription: ''
    })
    if (this.state.name.length <= 0) {
      this.setState({ errorName: 'Name required' })
      return false
    }
    if (this.state.phone.length <= 0) {
      this.setState({ errorPhone: 'Phone required' })
      return false
    }
    if (this.state.email.length <= 0) {
      this.setState({ errorEmail: 'Email required' })
      return false
    }
     if (this.state.city.length <= 0) {
      this.setState({ errorCity: 'City required' })
      return false
    }
    if (this.state.captcha.length <= 0) {
      this.setState({ errorCaptcha: 'Captcha required' })
      return false
    }
    // else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
    //   this.setState({ errorEmail: 'Email is not valid' })
    //   return false
    // }
    // if (this.state.companyName.length <= 0) {
    //   this.setState({ errorCompanyName: 'Company Name required' })
    //   return false
    // }

   

    const { service, name,phone, email,city, companyName, projectDescription } = this.state

    getLeads({
      services: service,
      name,
      phone,
      email,
      city,
      company_name: companyName,
      description: projectDescription,
      types:'2'
    }).then(result => {
      if (!result?.error) this.setState({ showThankYou: result.message, showError: false })
      else this.setState({ showThankYou: false, showError: result.message })
    })
  }
  
  handleSubmit1(e) {
    e.preventDefault()
    this.setState({
      errorService: '',
      errorName: '',
      errorPhone: '',
      errorCaptcha: '',
      errorEmail: '',
      errorCompanyName: '',
      errorCity: '',
      errorProjectDescription: ''
    })
    if (this.state.service.length <= 0) {
      this.setState({ errorName: 'Service required' })
      return false
    }
    if (this.state.name.length <= 0) {
      this.setState({ errorName: 'Name required' })
      return false
    }
    if (this.state.phone.length <= 0) {
      this.setState({ errorPhone: 'Phone required' })
      return false
    }
    if (this.state.city.length <= 0) {
      this.setState({ errorCity: 'City required' })
      return false
    }
    if (this.state.email.length <= 0) {
      this.setState({ errorEmail: 'Email required' })
      return false
    }
    // if (this.state.captcha.length <= 0) {
    //   this.setState({ errorCaptcha: 'Captcha required' })
    //   return false
    // }
    // else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
    //   this.setState({ errorEmail: 'Email is not valid' })
    //   return false
    // }
    // if (this.state.companyName.length <= 0) {
    //   this.setState({ errorCompanyName: 'Company Name required' })
    //   return false
    // }

   
    

    const { service, name,city,phone, email, companyName, projectDescription } = this.state

    getLeads({
      services: service,
      name,
      phone,
      email,
      city:city,
      company_name: companyName,
      description: projectDescription,
      types:'1'
    }).then(result => {
      if (!result?.error) this.props.history.push("thank-you")
      else this.setState({ showThankYou1: false, showError1: result.message })
    })
  }
  
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Best Architecture and Structural Design Company in Surat</title>
          <meta name="title" content="Best Architecture and Structural Design Company in Surat"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content="Zaid Excel Design is a leading architecture and design firm based in Surat, Gujarat, India. With comprehensive design services, sound budget management, and timely delivery, we can meet your project needs efficiently and effectively!"></meta>
        </Helmet>
        <section className="hero__block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="hero__text">
                  <p>Award winning &amp; trusted design firm</p>
                  <h1>Crafting spaces | Building lifestyles</h1>
                  {this.state.showThankYou1 && <div className="col-sm-12 alert alert-success">{this.state.showThankYou1}</div>}
                  {this.state.showError1 && <div className="col-sm-12 alert alert-danger">{this.state.showError1}</div>}
                  <form method="Post">
                    <div class="row">
                        <div class="form-group col-md-3">
                         <select required name="service" class="form-control" onChange={(e) => this.handleClick(e.target.value, 'service')}>
                         <option value="">Select</option>
                            <option value="Architect">Architect</option>
                            <option value="Interior">Interior</option>
                         </select>
                         <div style={{ color: 'red' }}>{this.state.errorService && this.state.errorService}</div>
                        </div>
                        <div class="form-group col-md-3">
                          <input required value={this.state.name} type="text" onChange={(e) => this.handleClick(e.target.value, 'name')} name="name" class="form-control" placeholder="Name" />
                          <div style={{ color: 'red' }}>{this.state.errorName && this.state.errorName}</div>
                        </div>
                        <div class="form-group col-md-3">
                          <input required value={this.state.phone} type="text" onChange={(e) => this.handleClick(e.target.value, 'phone')} name="phone" class="form-control" placeholder="Phone" />
                          <div style={{ color: 'red' }}>{this.state.errorPhone && this.state.errorPhone}</div>
                        </div>
                        <div class="form-group col-md-3">
                          <input required value={this.state.email} type="email" onChange={(e) => this.handleClick(e.target.value, 'email')} name="email" class="form-control" placeholder="Email" />
                          <div style={{ color: 'red' }}>{this.state.errorEmail && this.state.errorEmail}</div>
                        </div> 
                        <div class="form-group col-md-3">
                        <input required value={this.state.city} onChange={(e) => this.handleClick(e.target.value, 'city')} type="text" name="city" class="form-control" placeholder="City" />
                          </div>
                        <div class="form-group col-md-3">
                        <input  value={this.state.companyName} onChange={(e) => this.handleClick(e.target.value, 'companyName')} type="text" name="company_name" class="form-control" placeholder="Company Name ( Optional )" />
                          </div>
                          <div class="form-group col-md-4">
                            <textarea required value={this.state.projectDescription} name="des" onChange={(e) => this.handleClick(e.target.value, 'projectDescription')} id="" class="form-control" placeholder="Project Description"></textarea>
                            <div style={{ color: 'red' }}>{this.state.errorProjectDescription && this.state.errorProjectDescription}</div>
                          </div>
                        <div class="form-group mt-2">
                            <button type="submit" onClick={(e) => this.handleSubmit1(e)} class="btn"> GET A QUOTE </button>
                        </div>
                    </div>    
                  </form>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="prortfolio-block">
          <div className="container">
            <div className="row">
              {
                this.state.data.length>0 &&
                this.state.data.map((item, i) => {
                  if (i < this.state.limit) {
                    return item.ispopular ? (
                      <div className="col-md-4">
                        <div className="project__item">
                          <div className="project__img">
                            <Link to={`/portfolio/${item.alias}`}><img src={`${IMG_URI}/${item.image}`} alt={item.title} /></Link>
                          </div>
                          <div className="project__details">
                            <h3>{item.title}</h3>
                            <div className="project__category">{item.ribbon}</div>
                            <ul className="project__tags d-flex align-items-center">
                              <li>{item.types}</li>
                              <li>{item.text1}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : ""
                  }

                })
              }
            </div>
          </div>
        </section>
         <section className="awards-block">
          <div className="row align-items-center">
            <div className="awards__text-col col-lg-4">
              <div className="main__title">
                <span>AWARDS</span>
                <h2>Our Awards and Achievements</h2>
                <Link to="/awards" className="btn">Know More <i className="far fa-long-arrow-alt-right ml-2"></i></Link>
              </div>
            </div>
            <div className="col-lg-8">
              <Swiper slidesPerView={1} spaceBetween={30} className="award__slider" breakpoints={{
                0: { slidesPerView: 2, spaceBetween: 10, freeMode: true },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 2 },
              }}>
                <SwiperSlide>
                  <div className="awards__item">
                    <div className="awards__img">
                      <img src="img/award-1.png" alt="Award-1" />
                    </div>
                    <div className="awards__des">
                      <h4 className="text-white">Architecture</h4>
                      <p className="text-white mb-0">The Best Brand award under Architecture</p>
                      <p className="text-white mb-0">2018</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="awards__item">
                    <div className="awards__img">
                      <img src="img/award-2.png" alt="Award-2" />
                    </div>
                    <div className="awards__des">
                      <h4 className="text-white">600K+ Sq. ft</h4>
                      <p className="text-white mb-0">Creative, Functionality, and Aesthetics are the hallmarks of ZED.</p>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="awards__item">
                    <div className="awards__img">
                      <img src="img/award-1.png" alt="Awards" />
                    </div>
                    <div className="awards__des">
                      <h4 className="text-white">Architecture</h4>
                      <p className="text-white mb-0">The Best Brand award under Architecture</p>
                      <p className="text-white mb-0">2015</p>
                    </div>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </section>
        <section class="contact-block">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="contact__des">
                  <span>HIRE US</span>
                  <h1>Learn how we can create together. </h1>
                  {this.state.showThankYou && <div className="col-sm-12 alert alert-success">{this.state.showThankYou}</div>}
                  {this.state.showError && <div className="col-sm-12 alert alert-danger">{this.state.showError}</div>}
                  <h6>Services</h6>
                  <ul class="d-flex align-items-center flex-wrap">
                    <li>
                      <a id={this.state.service === 'Architect' && "blackButton"} onClick={() => this.handleClick('Architect', 'service')}>
                        Architect
                      </a>
                    </li>
                    
                    <li>
                      <a id={this.state.service === 'Interior' && "blackButton"} onClick={() => this.handleClick('Interior', 'service')}>
                        Interior
                      </a>
                    </li>
                    
                  </ul>
                  <div class="contact__form">
                    <form method="Post">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <input required value={this.state.name} type="text" onChange={(e) => this.handleClick(e.target.value, 'name')} name="name" class="form-control" placeholder="Name" />
                          <div style={{ color: 'red' }}>{this.state.errorName && this.state.errorName}</div>
                        </div>
                        <div class="form-group col-md-6">
                          <input required value={this.state.phone} type="text" onChange={(e) => this.handleClick(e.target.value, 'phone')} name="phone" class="form-control" placeholder="Phone" />
                          <div style={{ color: 'red' }}>{this.state.errorPhone && this.state.errorPhone}</div>
                        </div>
                      </div>
                      <div class="form-group">
                          <input required value={this.state.email} type="email" onChange={(e) => this.handleClick(e.target.value, 'email')} name="email" class="form-control" placeholder="Email" />
                          <div style={{ color: 'red' }}>{this.state.errorEmail && this.state.errorEmail}</div>
                        </div>
                      <div class="form-group">
                        <input  value={this.state.companyName} onChange={(e) => this.handleClick(e.target.value, 'companyName')} type="text" name="company_name" class="form-control" placeholder="Company Name ( Optional )" />
                      </div>
                      <div class="form-group">
                        <textarea required value={this.state.projectDescription} name="des" onChange={(e) => this.handleClick(e.target.value, 'projectDescription')} id="" class="form-control" placeholder="Project Description"></textarea>
                        <div style={{ color: 'red' }}>{this.state.errorProjectDescription && this.state.errorProjectDescription}</div>
                      </div>
                       <ReCAPTCHA
                        sitekey="6LeJYnUiAAAAAPgUXxsI_sMxfLF66HUDqbc4olyh"
                        onChange={this.handleChange}
                      >
                      <div style={{ color: 'red' }}>{this.state.errorCaptcha && this.state.errorCaptcha}</div>
                      </ReCAPTCHA>
                      <div class="form-group mt-2">
                        <button type="submit" onClick={(e) => this.handleSubmit(e)} class="btn">Submit <i class="far fa-long-arrow-alt-right ml-2"></i></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="design__banner-col col-lg-6 text-center"><div class="design__img"><img src="/img/design-shape_animated.gif" class="in-svg" alt="Design Shape" /></div></div>
            </div>
          </div>
        </section>
      </Layout >
    )
  }
}
