import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer--block">
      <div className="footer__main">
        <div className="container-fluid">
          <div className="row">
            <div className="footer__top-text col-lg-12 text-center">
              <h2 className="mb-0 text-white">
                Crafting Spaces | Building Lifestyles
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="footer__column col-lg-6 col-md-6">
              <div className="footer__logo">
                <Link to="/">
                  <img src="/img/logo-white.svg" alt="zaid" />
                </Link>
              </div>
              <div className="footer__text mt-4">
                <p>
                  Zaid Excel Design is a leading architecture & design firm
                  based in Surat, Gujarat, India. At Zaid Excel Design we
                  envision providing unrivalled designs by keeping eye on the
                  tiny details & processes that lead to curating elegant,
                  effortless, and significant forms of architecture. The team at
                  Zaid Excel Design believes in creating visually appealing
                  designs while keeping the project actualities in mind and
                  delivering captivating projects that the clients had thought
                  of.
                </p>
                <p className="copyright text-white">© Zaid Excel Design 2024</p>
              </div>
            </div>
            <div className="footer__column col-lg-3 col-md-3">
              <h4>HAVE A PROJECT IN MIND?</h4>
              <ul>
                <li>
                  <Link to="/contact">Commercial</Link>
                </li>
                <li>
                  <Link to="/contact">Interior Design</Link>
                </li>
                <li>
                  <Link to="/contact">Residential</Link>
                </li>
              </ul>
            </div>
            <div className="footer__column col-lg-3 col-md-3">
              <h4>CONTACT US</h4>
              <p className="email">
                <Link to="#">info@zaidexceldesign.com</Link>
              </p>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/zaid-excel-design-official/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin-in"></i>&nbsp;Linkedin
                  </a>
                </li>
                <li>
                  <a href="https://x.com/ZaidExcelDesign?s=20" target="_blank">
                    <i class="fab fa-twitter"></i>&nbsp;Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/zaidexceldesign/"
                    target="_blank"
                  >
                    <i class="fab fa-instagram"></i>&nbsp;Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://facebook.com/zaidexceldesign"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f"></i>&nbsp;Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@zaidexceldesign"
                    target="_blank"
                  >
                    <i class="fab fa-youtube"></i>&nbsp;Youtube
                  </a>
                </li>
                <li>
                  <Link to="/terms">Privacy and Terms</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
