export const callApi = async (obj, data = null, options = null, params = null) => {
    let o = {
        method: obj?.method,
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json"
        }
    }

    if (data) o = { ...o, body: JSON.stringify(data) }
    let url = obj?.url
    if (params) Object.values(params).map(param => url += `/${param}`)

    const res = await fetch(url, o)
    const { status } = res

    if (status !== "OK") {
        return res.json()
    }
    else {
        return false
    }
}