import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout"
import { Link } from 'react-router-dom'

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>404 | ZED Design</title>
          <meta name="title" content="404 | ZED Design"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>       
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <h1 className="text-center">404</h1>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="our__mission-block">
          <div className="container">
            <div className="row justify-content-center">

              <div className="col-lg-12 col-md-12">
                <div className="our__mission-text">
                  <p>Page not found............</p>
                 <Link to="/" className="btn">Go to home page</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
