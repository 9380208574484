import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout"
import { Link } from 'react-router-dom'

export default class Thankyou extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Thank You | ZED Design</title>
          <meta name="title" content="Thank You | ZED Design"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>       
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <h1 className="text-center">Thank You</h1>
                  
                <Link to='/'  className="btn"> Back </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </Layout>
    )
  }
}
