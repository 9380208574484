import React, { Component } from 'react'

export default class RedirectToBlog extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.match.params.id)
      this.props.history.push(`/blog/${this.props.match.params.id}`)
    else
      this.props.history.pop()
  }

  render() {
    return <div></div>
  }
}
