import { callApi } from "./utils"
import {
    GET_PROCESSES, CONTACT_US, LEADS, GET_POSTS, GET_POST, GET_POST_CATEGORIES,
    GET_COUNTRIES, GET_STATES, GET_WORKS, GET_WORK,GET_WORKNEXT,GET_WORKGROUP, GET_POST_IMAGES, GET_WORK_IMAGES
} from "./config/api"

export const getAllProcesses = async () => {
    const data = await callApi(GET_PROCESSES)
    if (!data) {
        console.error("Something is wrong in processes")
        return false
    }
    return data
}

export const getContactUs = async (userData) => {
    const data = await callApi(CONTACT_US, userData)
    if (!data) {
        console.error("Something is wrong in contactus form")
        return false
    }
    return data
}

export const getLeads = async (userData) => {
    const data = await callApi(LEADS, userData)
    if (!data) {
        console.error("Something is wrong in Leads form")
        return false
    }
    return data
}

export const getAllPosts = async (category = null) => {
    const data = await callApi(GET_POSTS, { category })
    if (!data) {
        console.error("Something is wrong in Posts")
        return false
    }
    return data
}

export const getAllWorks = async () => {
    const data = await callApi(GET_WORKS)
    if (!data) {
        console.error("Something is wrong in Works")
        return false
    }
    return data
}

export const getWork = async (id) => {
    const data = await callApi(GET_WORK, null, null, { id })
    if (!data) {
        console.error("Something is wrong in Work")
        return false
    }
    return data
}

export const getWorkNext = async (id) => {
    const data = await callApi(GET_WORKNEXT, null, null, { id })
    if (!data) {
        console.error("Something is wrong in Work")
        return false
    }
    return data
}

export const getWorkGroup = async (id) => {
    const data = await callApi(GET_WORKGROUP, null, null, { id })
    if (!data) {
        console.error("Something is wrong in Work")
        return false
    }
    return data
}

export const getPost = async (id) => {
    const data = await callApi(GET_POST, null, null, { id })
    if (!data) {
        console.error("Something is wrong in Posts")
        return false
    }
    return data
}

export const getPostImages = async (id) => {
    const data = await callApi(GET_POST_IMAGES, null, null, { id })
    if (!data) {
        console.error("Something is wrong in Posts")
        return false
    }
    return data
}

export const getWorkImages = async (id) => {
    const data = await callApi(GET_WORK_IMAGES, null, null, { id })
    if (!data) {
        console.error("Something is wrong in Posts")
        return false
    }
    return data
}

export const getAllPostCategories = async () => {
    const data = await callApi(GET_POST_CATEGORIES)
    if (!data) {
        console.error("Something is wrong in post categories")
        return false
    }
    return data
}

export const getAllCountries = async () => {
    const data = await callApi(GET_COUNTRIES)
    if (!data) {
        console.error("Something is wrong in coutries")
        return false
    }
    return data
}

export const getAllStates = async () => {
    const data = await callApi(GET_STATES)
    if (!data) {
        console.error("Something is wrong in states")
        return false
    }
    return data
}
