import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout"

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Terms &amp; Privacy Condition Notice | ZED Design</title>
          <meta name="title" content="Terms &amp; Privacy Condition Notice | ZED Design"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>       
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <h1 className="text-center">PRIVACY AND TERMS</h1>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="our__mission-block">
          <div className="container">
            <div className="row justify-content-center">

              <div className="col-lg-12 col-md-12">
                <div className="our__mission-text">
                  <h2>Privacy Statement</h2>
                  <p>The following are terms & conditions of a legal document between you and Zaid excel design. By browsing, accessing data or using this Website, you acknowledge & confirm that you have read, understood & agree to be bound by these terms and to comply with all applicable laws & regulations. If you are not ready to agree to these terms, you shall not be authorized to use this website and are directed to discontinue your use immediately.</p>
                  <p>Zaid Excel Design may, without any announcement, at any time revise these terms & conditions or any other information contained in this website.</p>

                  <h2>Intellectual Property Rights</h2>
                  <p>Zaid excel design is a trademark of Zaid Excel Design. All rights are reserved. No copyrighted drawings or materials, trademarks or any other proprietary information, including, without limitation, photographs, images, or renderings, described or provided in Zaid Excel Design website may be used by any party except without the prior written consent of Zaid Excel Design. All the drawings & work delivered to our clients are permitted to use according to promised deliverables & contracts.</p>
                  <p>An architect or any personnel uses a part of the architectural design of Zaid Excel Design to build his building/constructions, without the prior permission of the Zaid Excel Design who owns the copyright from which the other architect derives his/her work, it would amount to infringement.</p>
                  <p>However, not all references amount to infringement of copyright. Copyright law permits some portions of a copyrighted work to be used without the author's authorization for specific purposes such as comment, news, press coverage, criticism, teaching etc.</p>

                  <h2>Disclaimer</h2>
                  <p>Zaid Excel Design conveys no warranty of any sort concerning the subject matter included herein or the completeness or accuracy of Zaid Excel Website. Zaid Excel Design is not responsible for any actions (or lack thereof) taken as a result of depending on or in any way using the information contained in this website & in no event shall be liable for any damages resulting from dependence on or use of such information. The information contained on this Zaid Excel Design website is provided purely for informational purposes only & should not be construed as design, construction or other professional advice of any kind, on any subject matter.</p>
                  <p>You must not act or refrain from acting based on any content included in this Website without taking the apt professional advice on the particular facts & situations at issue. The content of this Website contains general information and may not reflect the latest professional standards, rules and/or laws. Zaid Excel Design expressly denies all liabilities in respect to actions taken or not taken based on any or all the contents of this Website.</p>

                  <h2>Privacy Statement</h2>
                  <p>Zaid Excel Design is dedicated to protecting the privacy of our customers/visitors to our website. When you provide your personal information to us in any form, we protect the privacy & confidentiality of that particular information. Zaid Excel Design's goal is to collect & use only that information that we consider is essential for our lawful business interests. When you browse through our website, you do so obscurely. However, Zaid Excel Design does collect limited personal information about you in some circumstances.</p>
                  <p>One way you do provide us with your personal data/information is by communicating to us by sending an email, whether through your own email system or using a “Contact Us” form on the site. If you send us an email, your email address and the contents of your message will be captured. This includes text, audio, video, & graphic information included in the message. Your e-mail address & the information included in your email will be used to respond to you accordingly, to address issues you identify or the queries that you may have, or to forward your message to the appropriate Zaid Excel Design employees for a response.</p>
                  <p>Your email address will not be used for commercial purposes, and Zaid Excel Design will not sell your email address to any third party. Any personal information you choose to provide to Zaid Excel Design will be retained by Zaid Excel Design by our standard corporate policies.</p>
                  <p>This Website makes use of cookies. Cookies are elements of information transferred to the browsing computer and stored for record-keeping purposes. Understanding how a website visitor is using the site through cookies enables us to tailor our content & services to more effectively meet visitors’ preferences & demands.</p>
                  <p>Zaid Excel Design uses standard security measures to protect the information & data under our control, including firewalls & other security measures. Although the intrinsic dangers of the Internet mean we cannot promise or guarantee the protection of your information/data against misuse, loss, or alteration, Zaid Excel Design pursuits to protect all of your personal data & information.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
