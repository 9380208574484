import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./containers/Home"
import About from "./containers/About"
import Blog from "./containers/Blog"
import BlogDetails from "./containers/BlogDetails"
import Service from "./containers/Service"
import Contact from "./containers/Contact"
import Portfolio from "./containers/Portfolio"
import PortfolioDetails from "./containers/PortfolioDetails"
import Process from "./containers/Process"
import Terms from "./containers/Terms"
import Testimonials from "./containers/Testimonials"
import Awards from "./containers/Awards"
import NotFound from "./containers/404"
import RedirectTo from './containers/RedirectTo';
import RedirectToBlog from './containers/RedirectToBlog';
import LandingPage from './containers/LandingPage';
import Thankyou from './containers/Thankyou';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={About} exact path="/about" />
        <Route component={Blog} exact path="/blog" />
        <Route component={BlogDetails} exact path="/blog/:id" />
        <Route component={Contact} exact path="/contact" />
        <Route component={LandingPage} exact path="/structural-design-company" />
        <Route component={Thankyou} exact path="/thank-you" />
        <Route component={Portfolio} exact path="/portfolio" />
        <Route component={PortfolioDetails} exact path="/portfolio/:id" />
        <Route component={Process} exact path="/process" />
        <Route component={Service} exact path="/service" />
        <Route component={Terms} exact path="/terms" />
        <Route component={Testimonials} exact path="/testimonials" />
        <Route component={Awards} exact path="/awards" />
        
        <Route component={RedirectTo} exact path="/redirectto/:id" />
        <Route component={RedirectToBlog} exact path="/redirecttoblog/:id" />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
