import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { getAllPosts } from "../apiFunctions"
import { Link } from 'react-router-dom'
import { IMG_URI } from '../config/api'

export default class BlogContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }
  componentDidMount() {
    getAllPosts().then(result => {
      this.setState({ data: result.data })
    })
  }
  render() {
    return (
      <div className="row">
        <div className="awards__title-col col-lg-4">
          <div className="main__title">
            <span>BLOGS</span>
            <h2 className="w-100">Our Thoughts on building great architecture & team culture</h2>
          </div>
        </div>
        <div className="col-lg-8">
          <Swiper slidesPerView={1} spaceBetween={30} className="blog__slider" breakpoints={{
            0: { slidesPerView: 1.2 },
            768: { slidesPerView: 2.5 },
            1024: { slidesPerView: 2.5 },
          }}>
            {
              this.state.data.length && this.state.data.map(blog => (
                <SwiperSlide key={blog.alias}>
                  <div className="blog__item">
                    <div className="blog__img">
                      <Link to={`/blog/${blog.alias}`}>
                        <img src={`${IMG_URI}/${blog.image}`} alt="Blog" />
                      </Link>
                    </div>
                    <div className="blog__des">
                      <span>{blog.title}</span>
                      <h5>{blog.description}</h5>
                      <Link to={`/blog/${blog.alias}`}><i className="far fa-long-arrow-alt-right"></i></Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    )
  }
}
