import React, { Component,useEffect } from 'react'
import Layout from "../components/Layout"
import { getContactUs } from "../apiFunctions"
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";

export default class Contact extends Component {
  constructor() {
    super();
    this.state = {
      service: '',
      name: '',
      phone: '',
      email: '',
      captcha: '',
      companyName: '',
      projectDescription: '',
      errorName: '',
      errorPhone: '',
      errorCaptcha: '',
      errorEmail: '',
      errorCompanyName: '',
      errorProjectDescription: '',
      showThankYou: false,
      showError: false
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClick(value, key) {
    this.setState({ [key]: value })
  }

handleChange = value => {
    this.setState({ captcha: value });
    
  };

  

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      errorName: '',
      errorPhone: '',
      errorCaptcha: '',
      errorEmail: '',
      errorCompanyName: '',
      errorProjectDescription: ''
    })
    if (this.state.name.length <= 0) {
      this.setState({ errorName: 'Name required' })
      return false
    }
    if (this.state.phone.length <= 0) {
      this.setState({ errorPhone: 'Phone required' })
      return false
    }
    if (this.state.email.length <= 0) {
      this.setState({ errorEmail: 'Email required' })
      return false
    }
    if (this.state.captcha.length <= 0) {
      this.setState({ errorCaptcha: 'Captcha required' })
      return false
    }
    // else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
    //   this.setState({ errorEmail: 'Email is not valid' })
    //   return false
    // }
    // if (this.state.companyName.length <= 0) {
    //   this.setState({ errorCompanyName: 'Company Name required' })
    //   return false
    // }

    if (this.state.projectDescription.length <= 0) {
      this.setState({ errorProjectDescription: 'Project Description required' })
      return false
    }

    const { service, name,phone, email, companyName, projectDescription } = this.state

    getContactUs({
      services: service,
      name,
      phone,
      email,
      company_name: companyName,
      description: projectDescription
    }).then(result => {
      if (!result?.error) this.setState({ showThankYou: result.message, showError: false })
      else this.setState({ showThankYou: false, showError: result.message })
    })
  }
  
  render() {
    
    return (
      
      <Layout>
        <Helmet>
            <title>Contact Us | Top Architects in Surat - ZED</title>
            <meta name="title" content="ZED : Contact Surat's One of the Best Architecture Firm"></meta>
            <meta name="keywords" content="Top architects in Surat"></meta>
            <meta name="description" content="ZAID Excel Design, Surat’s well-known and best Architecture firm. For top architects in Surat, Contact Today!!"></meta>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16797232369"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'AW-16797232369');
            `}
          </script>
        </Helmet>
        <section class="contact-block">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="contact__des">
                  <span>HIRE US</span>
                  <h1>Learn how we can create together. </h1>
                  {this.state.showThankYou && <div className="col-sm-12 alert alert-danger">{this.state.showThankYou}</div>}
                  {this.state.showError && <div className="col-sm-12 alert alert-danger">{this.state.showError}</div>}
                  <h6>Services</h6>
                  <ul class="d-flex align-items-center flex-wrap">
                    <li className={this.state.service==='Architect' ? "active" : ""}>
                      <a id={this.state.service === 'Architect' && "blackButton"} onClick={() => this.handleClick('Architect', 'service')}>
                        Architect
                      </a>
                    </li>
                    
                    <li className={this.state.service==='Interior' ? "active" : ""}>
                      <a id={this.state.service === 'Interior' && "blackButton"} onClick={() => this.handleClick('Interior', 'service')}>
                        Interior
                      </a>
                    </li>
                    
                  </ul>
                  <div class="contact__form">
                    <form method="Post">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <input required value={this.state.name} type="text" onChange={(e) => this.handleClick(e.target.value, 'name')} name="name" class="form-control" placeholder="Name" />
                          <div style={{ color: 'red' }}>{this.state.errorName && this.state.errorName}</div>
                        </div>
                        <div class="form-group col-md-6">
                          <input required value={this.state.phone} type="text" onChange={(e) => this.handleClick(e.target.value, 'phone')} name="phone" class="form-control" placeholder="Phone" />
                          <div style={{ color: 'red' }}>{this.state.errorPhone && this.state.errorPhone}</div>
                        </div>
                      </div>
                      <div class="form-group">
                          <input required value={this.state.email} type="email" onChange={(e) => this.handleClick(e.target.value, 'email')} name="email" class="form-control" placeholder="Email" />
                          <div style={{ color: 'red' }}>{this.state.errorEmail && this.state.errorEmail}</div>
                        </div>
                      <div class="form-group">
                        <input  value={this.state.companyName} onChange={(e) => this.handleClick(e.target.value, 'companyName')} type="text" name="company_name" class="form-control" placeholder="Company Name ( Optional )" />
                      </div>
                      <div class="form-group">
                        <textarea required value={this.state.projectDescription} name="des" onChange={(e) => this.handleClick(e.target.value, 'projectDescription')} id="" class="form-control" placeholder="Project Description"></textarea>
                        <div style={{ color: 'red' }}>{this.state.errorProjectDescription && this.state.errorProjectDescription}</div>
                      </div>
                       <ReCAPTCHA
                        sitekey="6LeJYnUiAAAAAPgUXxsI_sMxfLF66HUDqbc4olyh"
                        onChange={this.handleChange}
                      >
                      <div style={{ color: 'red' }}>{this.state.errorCaptcha && this.state.errorCaptcha}</div>
                      </ReCAPTCHA>
                      <div class="form-group mt-2">
                        <button type="submit" onClick={(e) => this.handleSubmit(e)} class="btn">Submit <i class="far fa-long-arrow-alt-right ml-2"></i></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="design__banner-col col-lg-6 text-center"><div class="design__img"><img src="/img/design-shape_animated.gif" class="in-svg" alt="Design Shape" /></div></div>
            </div>
          </div>
        </section>
      </Layout>
      
    )
  }
}
