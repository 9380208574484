import React, { Component } from 'react'
import Layout from "../components/Layout"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import BlogContainer from '../components/BlogContainer'
import { Autoplay, EffectCreative, EffectFade } from 'swiper'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default class Services extends Component {
    state = {
        show: false,
        show1: false,
    }

    toggle = () => this.setState((currentState) => ({ show: !currentState.show }));

    toggle1 = () => this.setState((currentState1) => ({ show1: !currentState1.show1 }));

    toggle2 = () => this.setState((currentState2) => ({ show2: !currentState2.show2 }));

    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Best Structural Designer | Best Interior Designer in Surat - ZED </title>
                    <meta name="title" content="Best Structural Designer | Best Interior Designer in Surat - ZED"></meta>
                    <meta name="keywords" content="best interior designer in Surat, best structural designer in Surat"></meta>
                    <meta name="description" content="Searching the best interior designer and structural designer in Surat? Team ZED is an expert in bestowing innovative designs & guaranteed satisfactory results."></meta>
                </Helmet>
                <section class="inner__banner-block">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="main__title w-100">
                                    <span>Services</span>
                                    <h1 class="text-center">Achieving client satisfaction by our innovative designs</h1>
                                </div>
                                <div class="inner__banner__slider swiper">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                            <img src="img/service_banner.png" class="w-100" alt="Service Banner" />
                                        </div>
                                        <div class="swiper-slide">
                                            <img src="img/portfolio_banner_2.jpg" class="w-100" alt="Portfolio Banner-2" />
                                        </div>
                                        <Swiper className="inner__banner__slider"
                                            loop={false}
                                            modules={[EffectFade, EffectCreative, Autoplay]}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false
                                            }}
                                            creativeEffect={{
                                                prev: {
                                                    shadow: true,
                                                    translate: ["-20%", 0, -1]
                                                },
                                                next: {
                                                    shadow: true,
                                                    translate: ["100%", 0, 0]
                                                }
                                            }}
                                        >
                                            <SwiperSlide>
                                                <img src="img/service_banner.jpg" class="w-100" alt="Service Banner" />
                                            </SwiperSlide>
                                            {/* <SwiperSlide>
                                                <img src="img/portfolio_banner_2.jpg" class="w-100" alt="Banner" />
                                            </SwiperSlide> */}
                                        </Swiper>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="services-block">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-2 col-md-3">
                                <div class="service__item">
                                    <div class="service__icon">
                                        <img src="img/service-1.png" alt="Service-1" />
                                    </div>
                                    <div class="service__des mt-4">
                                        <h5>Commercial</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3">
                                <div class="service__item">
                                    <div class="service__icon">
                                        <img src="img/service-1.png" alt="Service-1" />
                                    </div>
                                    <div class="service__des mt-4">
                                        <h5>Interior</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-3">
                                <div class="service__item">
                                    <div class="service__icon">
                                        <img src="img/service-1.png" alt="Service-1" />
                                    </div>
                                    <div class="service__des mt-4">
                                        <h5>Residential</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="services__category-block">
                    <div class="container">
                        <div class="row">
                            <div class="category__col--img col-md-6">
                                <div class="services__category-img padding__image">
                                    <Link to="#">
                                        <img src="img/service_1.png" alt="Service-1" />
                                    </Link>
                                </div>
                            </div>
                            <div class="category__col--des col-md-6">
                                <div class="services__category-des">
                                    <h3>Commercial Buildings & Office Spaces</h3>
                                    <p>Designing and developing commercial buildings & office spaces is our primary service. We own expertise, high-end knowledge & rich experience in crafting these projects. ZED has designed industry-leading projects for more than 7 years using best practice principles.</p>
                                    <p>Our art of work includes-</p>
                                    <ul class="list-unstyled">
                                        <li>- Pre-design</li>
                                        <li>- Site Design </li>
                                        <li>- Space Planning</li>
                                        <li>- Permit & Construction Drawings</li>
                                    </ul>
                                    {/* <a class="view__case" onClick={this.toggle}>View related case study {this.state.show ? <i class="fas fa-chevron-down"></i> : <i class="fas fa-chevron-up"></i>}</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.show &&
                    <section class="category__projects-block">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-5 col-md-6">
                                    <div class="category__project">
                                        <div class="category__projects-img padding__image">
                                            <Link to="#">
                                                <img src="img/project_1.jpg" alt="Project-1" />
                                            </Link>
                                        </div>
                                        <div class="category__projects-des">
                                            <h3>SK Office Space</h3>
                                            <p class="mb-0">25,000 - 30,000 SQFT</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="category__project">
                                        <div class="category__projects-img padding__image">
                                            <Link to="#">
                                                <img src="img/project_2.jpg" alt="Project-2" />
                                            </Link>
                                        </div>
                                        <div class="category__projects-des">
                                            <h3>Proj 2 Office Space</h3>
                                            <p class="mb-0">25,000 - 30,000 SQFT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section class="services__category-block">
                    <div class="container">
                        <div class="row">
                            <div class="category__col--des col-md-6">
                                <div class="services__category-des">
                                    <h3>Interior Design & Furniture</h3>
                                    <p>Having to own exquisite furniture and interior design, is a matter of a great deal! Our in-house interior designers have years of experience assisting us with offering the best design, procurement, and value for any workplace or residence.</p>
                                    <p>Our art of work includes-</p>
                                    <ul class="list-unstyled">
                                        <li>- Modular kitchen space</li>
                                        <li>- Painting & Wallpaper</li>
                                        <li>- Easy-to-maintain Layouts</li>
                                        <li>- Decor Accessories with Lighting</li>
                                    </ul>
                                    {/* <a class="view__case" onClick={this.toggle1}>View related case study {this.state.show1 ? <i class="fas fa-chevron-down"></i> : <i class="fas fa-chevron-up"></i>}</a> */}
                                </div>
                            </div>
                            <div class="category__col--img col-md-6">
                                <div class="services__category-img padding__image">
                                    <Link to="#">
                                        <img src="img/service_2.png" alt="Service-2" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.show1 &&
                    <section class="category__projects-block">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-5 col-md-6">
                                    <div class="category__project">
                                        <div class="category__projects-img padding__image">
                                            <Link to="#">
                                                <img src="img/project_1.jpg" alt="Project-1" />
                                            </Link>
                                        </div>
                                        <div class="category__projects-des">
                                            <h3>SK Office Space</h3>
                                            <p class="mb-0">25,000 - 30,000 SQFT</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="category__project">
                                        <div class="category__projects-img padding__image">
                                            <Link to="#">
                                                <img src="img/project_2.jpg" alt="Project-2" />
                                            </Link>
                                        </div>
                                        <div class="category__projects-des">
                                            <h3>Proj 2 Office Space</h3>
                                            <p class="mb-0">25,000 - 30,000 SQFT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section class="services__category-block">
                    <div class="container">
                        <div class="row">
                            <div class="category__col--img col-md-6">
                                <div class="services__category-img padding__image">
                                    <Link to="#">
                                        <img src="img/service_3.png" alt="Service-3" />
                                    </Link>
                                </div>
                            </div>
                            <div class="category__col--des col-md-6">
                                <div class="services__category-des">
                                    <h3>Residential buildings & Villas</h3>
                                    <p>Over the past 7 years, we have had worked on multiple residential projects including buildings and individual villas. From designing to execution, we have given 360-degree solutions to the clients under one roof.</p>
                                    <p>Our art of work includes-</p>
                                    <ul class="list-unstyled">
                                        <li>- Eco-friendly Ideas</li>
                                        <li>- Site Design </li>
                                        <li>- Airy space</li>
                                        <li>- Permit & Construction Drawings</li>
                                    </ul>
                                    {/* <a class="view__case" onClick={this.toggle2}>View related case study {this.state.show2 ? <i class="fas fa-chevron-down"></i> : <i class="fas fa-chevron-up"></i>}</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.show2 &&
                    <section class="category__projects-block">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-5 col-md-6">
                                    <div class="category__project">
                                        <div class="category__projects-img padding__image">
                                            <Link to="#">
                                                <img src="img/project_1.jpg" alt="Project-1" />
                                            </Link>
                                        </div>
                                        <div class="category__projects-des">
                                            <h3>SK Office Space</h3>
                                            <p class="mb-0">25,000 - 30,000 SQFT</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="category__project">
                                        <div class="category__projects-img padding__image">
                                            <Link to="#">
                                                <img src="img/project_2.jpg" alt="Project-2" />
                                            </Link>
                                        </div>
                                        <div class="category__projects-des">
                                            <h3>Proj 2 Office Space</h3>
                                            <p class="mb-0">25,000 - 30,000 SQFT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section class="design__ethos-block">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="main__title">
                                    <span>Our focus</span>
                                    <h2>Team ZED is an expert in bestowing innovative designs & guaranteed satisfactory results- C, I, and R</h2>
                                    <Link to="#"><img src="img/kraft_with_arrow.png" alt="Kraft with arrow"/></Link>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="design__ethos-img">
                                    <img src="img/design_ethos.svg" alt="Design Ethos"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="blogs-block">
                    <BlogContainer />
                </section>
            </Layout>
        )
    }
}
