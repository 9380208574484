import React, { Component } from 'react'
import Layout from "../components/Layout"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { getAllProcesses } from "../apiFunctions"
import { Autoplay, EffectCreative, EffectFade, Navigation } from 'swiper'
import BlogContainer from '../components/BlogContainer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default class Process extends Component {
  constructor() {
    super()
    this.state = {
      data: []
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    getAllProcesses().then(result => {
      console.log(result.data)
      this.setState({ data: result.data })
    }).catch(err => {
      console.log(err)
    })
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Latest Design for a Better and Sustainable Future | Process : ZED</title>
          <meta name="title" content="Latest Design for a Better and Sustainable Future | Process : ZED"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>       
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>Our Approach</span>
                  <h1 className="text-center">We transform a concept into a construction</h1>
                </div>
                <Swiper className="inner__banner__slider"
                  loop={false}
                  modules={[EffectFade, EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: ["-20%", 0, -1]
                    },
                    next: {
                      shadow: true,
                      translate: ["100%", 0, 0]
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="img/process_banner.png" className="w-100" alt="Process Banner" />
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <img src="img/process_banner.jpg" className="w-100" alt="Banner" />
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="process-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="process__timeline d-flex flex-wrap">

                  {
                    this.state.data && this.state.data.map((item, i) => {
                      return <div className="process__point">
                        <span>PHASE {item.id}.</span>
                        <h4 className="mb-0">{item.title}</h4>
                      </div>
                    })
                  }
                </div >
              </div >
            </div >
            {
              this.state.data && this.state.data.map((item, i) => {
                return <div className="process__step">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="process__img">
                        <img src={`img/process-img-${item.id}.png`} alt={item.title} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="process__des">
                        <span>{item.title}</span>
                        <h3>{item.sort_description}</h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
        </section>

        <section class="functional__process-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="main__title">
                  <span>DESIGN ETHOS</span>
                  <h2 class="mb-5 text-white w-100">Know how ZED provide value to  their clients through functional process</h2>
                  <a href="#" class="for__desktop"><img src="img/kraft_with_arrow.png" alt="Kraft with Arrow" /></a>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="functional__process d-flex justify-content-end">
                  <img src="img/zed_value.svg" alt="Zed Value" />
                </div>
                <a href="#" class="for__mobile"><img src="img/kraft_with_arrow.png" alt="Kraft with Arrow" /></a>
              </div>
            </div>
          </div>
        </section>
        <section className="blogs-block">
          <BlogContainer />
        </section>
      </Layout>
    )
  }
}
