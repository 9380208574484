import React, { Component } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import {
  getWork,
  getWorkImages,
  getWorkNext,
  getWorkGroup,
} from "../apiFunctions";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { IMG_URI } from "../config/api";
import { Helmet } from "react-helmet";

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ribbon: "",
      title: "",
      headline: "",
      title1: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      description: "",
      types: "",
      text1: "",
      text2: "",
      text3: "",
      text4: "",
      text5: "",
      text6: "",
      para1: "",
      para2: "",
      para3: "",
      image: "",
      image1: "",
      img: "",
      alias: "",
      data: [],
      data1: [],
    };

    getWorkGroup(this.props.match.params.id).then((result) => {
      this.setState({
        data: result.data,
        types1: "",
        types2: "",
        types3: "",
        types4: "",
        types5: "",
      });

      this.state.data.length &&
        this.state.data.map((item, i) => {
          if (item.types == "1") {
            this.state.types1 = "1";
          } else if (item.types == "2") {
            this.state.types2 = "1";
          } else if (item.types == "3") {
            console.log("Okay okay");
            this.state.types3 = "1";
          } else if (item.types == "4") {
            this.state.types4 = "1";
          } else if (item.types == "5") {
            this.state.types5 = "1";
          }
        });
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    getWork(this.props.match.params.id).then((res) =>
      this.setState({
        ribbon: res.data.ribbon,
        title: res.data.title,
        meta_title: res.data.meta_title,
        meta_keywords: res.data.meta_keywords,
        meta_description: res.data.meta_description,
        headline: res.data.headline,
        description: res.data.description,
        types: res.data.types,
        text1: res.data.text1,
        text2: res.data.text2,
        text3: res.data.text3,
        text4: res.data.text4,
        text5: res.data.text5,
        text6: res.data.text6,
        para1: res.data.para1,
        para2: res.data.para2,
        para3: res.data.para3,
        image: res.data.image,
        img: res.data.img,
      })
    );

    getWorkImages(this.props.match.params.id).then((result) => {
      this.setState({ data1: result.data });
    });

    getWorkNext(this.props.match.params.id).then((res) => {
      this.setState({
        title1: res.data.title,
        image1: res.data.image,
        alias: res.data.alias,
      });
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.state.title}</title>
          <meta name="title" content={`${this.state.meta_title}`}></meta>
          <meta name="keywords" content={`${this.state.meta_keywords}`}></meta>
          <meta
            name="description"
            content={`${this.state.meta_description}`}
          ></meta>
        </Helmet>

        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title w-100">
                  <span>{this.state.ribbon}</span>
                  <h1 className="text-center">{this.state.headline}</h1>
                </div>
                <Swiper className="inner__banner__slider">
                  {this.state.data1.length > 0 &&
                    this.state.data1.map((item, i) => {
                      return item.types == "1" ? (
                        <SwiperSlide>
                          <img
                            src={`${IMG_URI}/${item.img}`}
                            className="w-100"
                            alt={item.img}
                          />
                        </SwiperSlide>
                      ) : (
                        ""
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__details-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="portfolio__col portfolio__details">
                  <h3>{this.state.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: this.state.description }}
                  ></p>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="portfolio__col portfolio__banner">
                  <ul className="list-unstyled d-flex flex-wrap">
                    {this.state.text1 && <li>{this.state.text1}</li>}
                    {this.state.text2 && <li>{this.state.text2}</li>}
                    {this.state.text3 && <li>{this.state.text3}</li>}
                    {this.state.text4 && <li>{this.state.text4}</li>}
                    {this.state.text5 && <li>{this.state.text5}</li>}
                    {this.state.text6 && <li>{this.state.text6}</li>}
                  </ul>
                  {/* <div className="portfolio__col-img">
                    {this.state.data1.length > 0 &&
                      this.state.data1.map((item, i) => {
                        return item.types == "2" ? (
                          <img
                            key={i}
                            src={`${IMG_URI}/${item.img}`}
                            alt={item.img}
                          />
                        ) : (
                          ""
                        );
                      })}
                  </div> */}
                  <div className="portfolio__col-img">
                    {this.state.data1.length > 0 &&
                      (() => {
                        const imageData = this.state.data1.find(
                          (item) => item.types == "2"
                        );
                        if (imageData) {
                          return (
                            <img
                              key={imageData.img}
                              src={`${IMG_URI}/${imageData.img}`}
                              alt={imageData.img}
                            />
                          );
                        } else {
                          return null; // or whatever you want to render when no matching image is found
                        }
                      })()}
                  </div>

                  <p className="mt-2 mb-0 text-center">
                    Gallery View of {this.state.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.para1 ? (
          <section className="portfolio__quote-block for__mobile">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h5>IMPRESSIONS</h5>
                  <p dangerouslySetInnerHTML={{ __html: this.state.para1 }}></p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {this.state.types3 == "1" ? (
          <section className="portfolio__gallery-block">
            <Swiper
              className="portfolio__gallery"
              modules={[Pagination]}
              slidesPerView={1}
              loop={true}
              spaceBetween={30}
              breakpoints={{
                0: {
                  spaceBetween: 15,
                  slidesPerView: 1.5,
                  pagination: {
                    el: ".swiper-pagination",
                    type: "progressbar",
                    clickable: true,
                  },
                  autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                  },
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
              }}
            >
              {this.state.data1.length > 0 &&
                this.state.data1.map((item, i) => {
                  return item.types == "3" ? (
                    <SwiperSlide>
                      <div className="portfolio__gallery_item">
                        <img
                          src={`${IMG_URI}/${item.img}`}
                          className="w-100"
                          alt={item.img}
                        />
                      </div>
                    </SwiperSlide>
                  ) : (
                    ""
                  );
                })}
            </Swiper>
          </section>
        ) : (
          ""
        )}
        {this.state.para1 ? (
          <section className="portfolio__quote-block for__desktop">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h5>IMPRESSIONS</h5>
                  <p dangerouslySetInnerHTML={{ __html: this.state.para1 }}></p>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <h5>RESULTS</h5>
                  <p dangerouslySetInnerHTML={{ __html: this.state.para2 }}></p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {this.state.para2 ? (
          <section className="portfolio__quote-block for__mobile">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h5>RESULTS</h5>
                  <p dangerouslySetInnerHTML={{ __html: this.state.para2 }}></p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {this.state.types4 == "1" ? (
          <section className="our_office-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="our_office__title">
                    <h4>Glimpses of {this.state.title}</h4>
                    <div className="swiper-pagination office-pagination"></div>
                  </div>
                </div>
              </div>
            </div>
            <Swiper className="our_office__slider">
              {this.state.data1.length > 0 &&
                this.state.data1.map((item, i) => {
                  return item.types == "4" ? (
                    <SwiperSlide>
                      <img
                        src={`${IMG_URI}/${item.img}`}
                        className="w-100"
                        alt={item.img}
                      />
                    </SwiperSlide>
                  ) : (
                    ""
                  );
                })}
            </Swiper>
          </section>
        ) : (
          ""
        )}
        {this.state.para3 ? (
          <section className="portfolio__long__text-block">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                  <h2>{this.state.para3}</h2>
                </div>
                <div className="col-md-12">
                  {this.state.data1.length > 0 &&
                    this.state.data1.map((item, i) => {
                      return item.types == "5" ? (
                        <img
                          src={`${IMG_URI}/${item.img}`}
                          alt={item.img}
                          className="w-100"
                        />
                      ) : (
                        ""
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <section className="next__project-block">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-md-5">
                <div className="main__title">
                  <span>NEXT PROJECT</span>
                  <h2 className="w-100 mb-5">{this.state.title1}</h2>
                  <Link to={`/redirectto/${this.state.alias}`}>
                    <img
                      src="/img/kraft_with_arrow.png"
                      alt="Kraft with arrow"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 col-md-7">
                <div className="next__project">
                  <img
                    src={`${IMG_URI}/${this.state.image1}`}
                    className="w-100"
                    alt="Project"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
