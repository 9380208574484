import React, { Component } from 'react'
import { getAllWorks } from "../apiFunctions"
import Layout from "../components/Layout"
import { Link } from 'react-router-dom'
import { IMG_URI } from '../config/api'
import { PORTFOLIO_COUNT } from '../config/index'
import { Helmet } from 'react-helmet'

export default class Portfolio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      limit: 3,
      data: [],
      showAll: false
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    getAllWorks().then(result => {
      this.setState({ data: result.data })
    })
  }

  handleLimit() {
    if (this.state.limit < this.state.data.length) {
      this.setState({ limit: this.state.limit + PORTFOLIO_COUNT })
    }
    if (this.state.limit >= this.state.data.length) {
      this.setState({ limit: this.state.data.length })
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>Explore our Portfolio of latest designs and work all across location: ZED</title>
            <meta name="title" content="Explore our Portfolio of latest designs and work all across location: ZED"></meta>
            <meta name="keywords" content=""></meta>
            <meta name="description" content="Get explore to wide range of our architectural projects of that we have finished. Which include some of the marvellous design which has been designed by our team"></meta>
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title w-100">
                  <span>PORTFOLIO</span>
                  <h1 className="text-center">Crafting spaces | Building lifestyles</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="prortfolio-block">
          <div className="container">
            <div className="row">
              {
                this.state.data.length>0 &&
                this.state.data.map((item, i) => {
                  if (i < this.state.limit) {
                    return item.ispopular ? (
                      <div className="col-md-4">
                        <div className="project__item">
                          <div className="project__img">
                            <Link to={`/portfolio/${item.alias}`}><img src={`${IMG_URI}/${item.image}`} alt={item.title} /></Link>
                          </div>
                          <div className="project__details">
                            <h3>{item.title}</h3>
                            <div className="project__category">{item.ribbon}</div>
                            <ul className="project__tags d-flex align-items-center">
                              <li>{item.types}</li>
                              <li>{item.text1}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : ""
                  }

                })
              }
            </div>
          </div>
        </section>
        <section className="portfolio__category-block">
          <div className="container">
            {
              this.state.data && this.state.data.map((item, i) => {
                if (i < this.state.limit) {
                    return  (
                    <div className="project__item row justify-content-center">
                      <div className="col-lg-6 col-md-6">
                        <div className="project__img">
                          <Link to={`/portfolio/${item.alias}`} className="padding__image"><img src={`${IMG_URI}/${item.image}`} alt={item.alias} /></Link>
                          <Link className="read__more" to={`/portfolio/${item.alias}`}>View Case <i className="far fa-long-arrow-alt-right ml-2"></i></Link>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-5">
                        <div className="project__details">
                          <h3>{item.title}</h3>
                          <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                          <ul className="project__tags d-flex align-items-center">
                            <li>{item.types}</li>
                            <li>{item.text1}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                
              })
            }
            {
              this.state.limit < this.state.data.length ?
                <div className="row">
                  <div className="col-md-12 text-center">
                    <a onClick={this.handleLimit.bind(this)} className="btn btn-more">LOAD MORE</a>
                  </div>
                </div>
                :
                ""
            }
          </div>
        </section>
      </Layout >
    )
  }
}
