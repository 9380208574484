import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import Layout from "../components/Layout"
// import BlogContainer from '../components/BlogContainer'
import { getPost, getPostImages, getAllPosts } from '../apiFunctions'
import { Link } from 'react-router-dom'
import { IMG_URI } from '../config/api'
import { Autoplay, EffectCreative, EffectFade, Navigation } from 'swiper'
import { Helmet } from 'react-helmet'

export default class BlogDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      category: "",
      description: "",
      title: "",
      meta_title:"",
      meta_keywords:"",
      meta_description:"",
      para1: "",
      para2: "",
      image: "",
      img: "",
      img_text: "",
      data: [],
      data1: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getPost(this.props.match.params.id).then(res => this.setState({
      category: res.data.post_category,
      description: res.data.description,
      title: res.data.title,
      meta_title: res.data.meta_title,
      meta_keywords: res.data.meta_keywords,
      meta_description: res.data.meta_description,
      para1: res.data.para1,
      para2: res.data.para2,
      image: res.data.image,
      img: res.data.img,
      img_text: res.data.img_text,
    }))

    getPostImages(this.props.match.params.id).then(result => {
      this.setState({ data: result.data })
    })
    getAllPosts().then(result => {
      this.setState({ data1: result.data })
    })
    //getPostImages(this.props.match.params.id).then(data => console.log(data))
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>{this.state.title}</title>
            <meta name="title" content={`${this.state.meta_title}`}></meta>
            <meta name="keywords" content={`${this.state.meta_keywords}`}></meta>
            <meta name="description" content={`${this.state.meta_description}`}></meta>
        </Helmet>
        <div className="social__links--fixed">
          <ul>
            <li>
              <a href="https://linkedin.com/company/zaid-excel-design" target="_blank"><i className="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a href="https://twitter.com/" target="_blank"><i className="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="https://instagram.com/zaidexceldesign/" target="_blank"><i className="fab fa-instagram"></i></a>
            </li>
            <li>
              <a href="https://facebook.com/zaidexceldesign" target="_blank"><i className="fab fa-facebook-f"></i></a>
            </li>
          </ul>
        </div>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title w-100">
                  <span>{this.state.category}</span>
                  <h1 className="text-center">{this.state.title}</h1>
                </div>
                <Swiper className="inner__banner__slider"
                  loop={false}
                  modules={[EffectFade, EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: ["-20%", 0, -1]
                    },
                    next: {
                      shadow: true,
                      translate: ["100%", 0, 0]
                    }
                  }}
                >
                  {
                    this.state.data.length>0 && this.state.data.map((item, i) => {
                      return item.types == "1" ? (
                        <SwiperSlide>
                          <img src={`${IMG_URI}/${item.img}`} className="w-100" alt={item.img} />
                        </SwiperSlide>
                      ) : ""
                    })
                  }
                </Swiper>

                {/* <div className="inner__banner__slider swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img src={`${IMG_URI}/${this.state.image}`} className="w-100" alt="Banner" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="blog__single-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog__single-des">
                  <p dangerouslySetInnerHTML={{ __html: this.state.para1 }}></p>

                  {
                    this.state.data.length>0 && this.state.data.map((item, i) => {
                      return item.types == "2" ? (
                        <><img className="w-100" src={`${IMG_URI}/${item.img}`} alt={item.img} /><p className="text-center mt-3"><i>{item.img_text}</i></p></>
                      ) : ""
                    })
                  }

                </div>
                <div className="blog__single-des">
                  <p>{this.state.para2}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="blogs-block">
          {/* <BlogContainer /> */}
          <div className="row">
        <div className="awards__title-col col-lg-4">
          <div className="main__title">
            <span>BLOGS</span>
            <h2 className="w-100">Our Thoughts on building great architecture & team culture</h2>
          </div>
        </div>
        <div className="col-lg-8">
          <Swiper slidesPerView={1} spaceBetween={30} className="blog__slider" breakpoints={{
            0: { slidesPerView: 1.2 },
            768: { slidesPerView: 2.5 },
            1024: { slidesPerView: 2.5 },
          }}>
            {
              this.state.data1.length>0 && this.state.data1.map(blog => (
                <SwiperSlide>
                  <div className="blog__item">
                    <div className="blog__img">
                      <Link to={`/redirecttoblog/${blog.alias}`}>
                        <img src={`${IMG_URI}/${blog.image}`} alt={blog.alias} />
                      </Link>
                    </div>
                    <div className="blog__des">
                      <span>{blog.title}</span>
                      <h5>{blog.description}</h5>
                      <Link to={`/redirecttoblog/${blog.alias}`}><i className="far fa-long-arrow-alt-right"></i></Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
        </section>
      </Layout>
    )
  }
}
