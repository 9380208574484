import React, { Component } from 'react'
import Layout from "../components/Layout"
import { getAllPosts, getAllPostCategories } from "../apiFunctions"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Autoplay, EffectCreative, EffectFade } from 'swiper'
import { Link } from "react-router-dom";
import { IMG_URI } from '../config/api'
import { Helmet } from 'react-helmet'
import { XBlock, XMasonry } from 'react-xmasonry'

export default class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      postsCategories: [],
      blog:'',
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    getAllPosts().then(result => {
      this.setState({ data: result.data })
    })

    getAllPostCategories().then(res => {
      this.setState({ postsCategories: res.data })
    })
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Our Latest articles and Blogs on Architectural &amp; Structural: ZED Blogs</title>
          <meta name="title" content="Our Latest articles and Blogs on Architectural &amp; Structural: ZED Blogs"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>BLOGS</span>
                  <h1 className="text-center">Our thoughts on building Great Architecture</h1>
                </div>
                <Swiper className="inner__banner__slider"
                  loop={false}
                  modules={[EffectFade, EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: ["-20%", 0, -1]
                    },
                    next: {
                      shadow: true,
                      translate: ["100%", 0, 0]
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/img/blog_banner.png" className="w-100" alt="Blog Banner" />
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <img src="/img/blog_banner.jpg" className="w-100" alt="Banner" />
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="blogs__filter-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="filter-button-group">
                  <ul className="d-flex align-items-center justify-content-center">
                    <li className={this.state.blog === '' ? "active" : ""} onClick={() => getAllPosts().then(result => this.setState({ data: result.data }))}>All Topics</li>
                    {
                      this.state.postsCategories && this.state.postsCategories.map((item, i) => {
                        return <li key={i} className={this.state.blog === `${item.post_category}` ? "active" : ""} onClick={() => getAllPosts(item.id).then(result => this.setState({blog:item.post_category, data: result.data }))} >{item.post_category}</li>
                      })
                    }
                  </ul>
                </div>
                <XMasonry className="portfolio-item row" maxColumns={3}>
                  {
                    this.state.data.length>0 && this.state.data.map((item, i) => {
                      return (
                        <XBlock key={i}>
                          <div className={`grid-item ${!item.image ? "text__block" : ""} ${item.category}`}>
                            <div className="blog__item">
                              {item.image && <div className="blog__img">
                                <Link to={`/blog/${item.alias}`}>
                                  <img src={`${IMG_URI}/${item.image}`} alt={item.alias} />
                                </Link>
                              </div>}
                              <div className="blog__des">
                                <span>{item.post_category}</span>
                                {item.image ? <h5>{item.title}</h5> : <h4>{item.title}</h4>}
                                {!item.image && <Link to={`/blog/${item.alias}`} className="arrow__link"><img src={"img/kraft_with_arrow.png"} alt="Kraft with arrow"/></Link>}
                              </div>
                            </div>
                          </div>
                        </XBlock>
                      )
                    })
                  }

                </XMasonry>
                {/* <nav className="filter__pagination" aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li className="page-item disabled">
                      <Link className="page-link" to="#"><i className="far fa-long-arrow-alt-left"></i></Link>
                    </li>
                    <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                    <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                    <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                    <li className="page-item">
                      <Link className="page-link" to="#"><i className="far fa-long-arrow-alt-right"></i></Link>
                    </li>
                  </ul>
                </nav> */}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
