import React, { Component } from 'react'
import Layout from "../components/Layout"
import { getAllPosts, getAllPostCategories } from "../apiFunctions"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Autoplay, EffectCreative, EffectFade } from 'swiper'
import { Link } from "react-router-dom";
import { IMG_URI } from '../config/api'
import { Helmet } from 'react-helmet'
import { XBlock, XMasonry } from 'react-xmasonry'
import BlogContainer from '../components/BlogContainer'

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>ZED | Testimonials</title>
          <meta name="title" content="ZED | Testimonials"></meta>
          <meta name="keywords" content=""></meta>
          <meta name="description" content=""></meta>       
        </Helmet>
        <section className="inner__banner-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>TESTIMONIALS</span>
                  <h1 className="text-center">What our client says about Zaid Excel Design</h1>
                </div>
                <Swiper className="inner__banner__slider"
                  loop={false}
                  modules={[EffectFade, EffectCreative, Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: true,
                      translate: ["-20%", 0, -1]
                    },
                    next: {
                      shadow: true,
                      translate: ["100%", 0, 0]
                    }
                  }}
                >
                  <SwiperSlide>
                    <img src="/img/blog_banner.png" className="w-100" alt="Blog Banner" />
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <img src="/img/blog_banner.jpg" className="w-100" alt="Banner" />
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonials--block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="testimonial--item">
                            <div className="user__message">
                                <p>We had one of the best experience with Zaid and his team.
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations.
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations. </p>
                            </div>
                            <div className="user__details">
                                <h4>Ramesh Bansal</h4>
                                <h5 className="mb-0">COO - Rajnidhi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial--item">
                            <div className="user__message">
                                <p>We had one of the best experience with Zaid and his team. 
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations. 
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations. </p>
                            </div>
                            <div className="user__details">
                                <h4>Ramesh Bansal</h4>
                                <h5 className="mb-0">COO - Rajnidhi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial--item">
                            <div className="user__message">
                                <p>We had one of the best experience with Zaid and his team.
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations.
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations. </p>
                            </div>
                            <div className="user__details">
                                <h4>Ramesh Bansal</h4>
                                <h5 className="mb-0">COO - Rajnidhi</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial--item">
                            <div className="user__message">
                                <p>We had one of the best experience with Zaid and his team.
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations.
                                    Zaid was terrific to work with. His knowledge, skills, vision surpassed our expectations. </p>
                            </div>
                            <div className="user__details">
                                <h4>Ramesh Bansal</h4>
                                <h5 className="mb-0">COO - Rajnidhi</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <section className="blogs-block">
          <BlogContainer />
        </section>
      </Layout>
    )
  }
}
