import React, { Component } from "react";
import Layout from "../components/Layout";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, EffectFade, Autoplay } from "swiper";
import BlogContainer from "../components/BlogContainer";
import $ from "jquery";
import { getAllWorks } from "../apiFunctions";
import { IMG_URI } from "../config/api";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      swiperImages: [
        ["img/home-banner-1.png", "img/home-banner-2.png"],
        ["img/home-banner-4.png", "img/home-banner-5.png"],
        ["img/home-banner-2.png", "img/home-banner-3.png"],
        ["img/home-banner-7.png", "img/home-banner-4.png"],
      ],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    var filtered_count = "0";
    $(".bottomScroller").bind("click", function () {
      $("html, body").animate(
        {
          scrollTop: $("#design__block").offset().top,
        },
        2000
      );
    });
    if (filtered_count > 0) {
      $(".bottomScroller").trigger("click");
    }
    getAllWorks().then((result) => {
      this.setState({ data: result.data });
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            ZAID: Best Architecture and Structural Design Firm in Surat
          </title>
          <meta
            name="title"
            content="ZAID: Best Architecture and Structural Design Firm in Surat"
          ></meta>
          <meta name="keywords" content=""></meta>
          <meta
            name="description"
            content="ZAID Excel Design is one of the leading and best architecture and structural design firm in Surat. We provide unrivalled designs by keeping eye on the tiny details & processes."
          ></meta>
        </Helmet>
        <div className="whatsApp__fixed">
          <a
            class="bg-white d-flex align-items-center justify-content-center rounded-circle whatsapp_icon"
            href="https://api.whatsapp.com/send?phone=918298292410&amp;text=Hi%20there,%20Interested%20in%20your%20services,%20Give%20me%20further%20information"
            target="_blank"
          >
            <img
              src="https://webapp.zaidexceldesign.com/public/img/icons/whatsapp.svg"
              alt="WhatsApp"
            />
          </a>  
        </div>
        <section className="hero__block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="hero__text">
                  <p>Award winning &amp; trusted design firm</p>
                  <h1>Crafting spaces | Building lifestyles</h1>
                  <Link to="/contact" className="btn">
                    GET A QUOTE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hero-banner">
          <div className="down__arrow">
            <Link className="bottomScroller" to="#design__block">
              <img src="img/kraft_papper.png" alt="Kraft Papper" />
              <img className="arrow" src="img/down_arrow.svg" alt="arrow" />
            </Link>
          </div>
          {/* <Swiper slidesPerView={1}
            modules={[Autoplay, EffectFade]}
            loop={true}
            className="hero__slider"
            autoplay={{
              delay: 4000,
              disableOnInteraction: false
            }}
            effect="fade"
          >
          
            <SwiperSlide>
              <div className="hero__slide">
                <div className="hero__slider__item">
                  <img src="img/home-banner-1.png" alt="home-banner-1"/>
                </div>
                <div className="hero__slider__item">
                  <img src="img/home-banner-2.png" alt="home-banner-2"/>
                </div> */}
          {/* <div className="hero__slider__item">
                  <img src="img/home-banner-3.png" alt="home-banner-3"/>
                </div> */}
          {/* </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="hero__slide">
                <div className="hero__slider__item">
                  <img src="/img/home-banner-4.png" alt="home-banner-4"/>
                </div>
                <div className="hero__slider__item">
                  <img src="/img/home-banner-5.png" alt="home-banner-5"/>
                </div> */}
          {/* <div className="hero__slider__item">
                  <img src="/img/home-banner-6.png" alt="home-banner-6"/>
                </div> */}
          {/* </div> */}
          {/* </SwiperSlide>

            <SwiperSlide>
              <div className="hero__slide">
                <div className="hero__slider__item">
                  <img src="img/home-banner-2.png" alt="home-banner-2"/>
                </div>
                <div className="hero__slider__item">
                  <img src="img/home-banner-3.png" alt="home-banner-3"/>
                </div> */}
          {/* <div className="hero__slider__item">
                  <img src="img/home-banner-1.png" alt="home-banner-1"/>
                </div> */}
          {/* </div>
            </SwiperSlide>
          </Swiper> */}
          {/* <Swiper
            slidesPerView={1}
            modules={[Autoplay, EffectFade]}
            loop={true}
            className="hero__slider"
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            effect="fade"
          >
            {[1, 2, 3].map((slideNumber) => (
              <SwiperSlide key={slideNumber}>
                <div className="hero__slide">
                  {[1, 2].map((bannerNumber) => (
                    <div className="hero__slider__item" key={bannerNumber}>
                      <img
                        src={`img/home-banner-${
                          (slideNumber - 1) * 2 + bannerNumber
                        }.png`}
                        alt={`home-banner-${
                          (slideNumber - 1) * 2 + bannerNumber
                        }`}
                      />
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
          <Swiper
            slidesPerView={1}
            modules={[Autoplay]}
            loop={true}
            className="hero__slider"
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            // effect="fade"
          >
            {this.state.swiperImages.map((slideImages, slideIndex) => (
              <SwiperSlide key={slideIndex}>
                <div className="hero__slide">
                  {slideImages.map((imagePath, imgIndex) => (
                    <div className="hero__slider__item" key={imgIndex}>
                      <img
                        src={imagePath}
                        alt={`home-banner-${slideIndex + 1}-${imgIndex + 1}`}
                      />
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
        <section id="design__block" className="design-block">
          <div className="container">
            <div className="row">
              <div className="design__text-col col-lg-6">
                <div className="design__des">
                  <h4>
                    Zaid Excel Design- Designing sustainable, timeless, and
                    efficient architecture
                  </h4>
                  <p>
                    Each project is designed with acute details to create
                    elegant, luxurious and modern spaces.
                  </p>
                  <Link to="/about" className="btn">
                    GET TO KNOW US{" "}
                    <i className="far fa-long-arrow-alt-right ml-2"></i>
                  </Link>
                </div>
              </div>
              <div className="design__banner-col col-lg-6 text-center">
                <div className="design__img">
                  <img
                    src="/img/design-shape_animated.gif"
                    className="in-svg"
                    alt="Design Shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="projects-block">
          <div className="container">
            {this.state.data.length &&
              this.state.data.map((item, i) => {
                return item.ispopular ? (
                  <div key={item.alias} className="project--item">
                    <div className="row justify-content-center">
                      <div className="col-lg-5">
                        <div className="project__banner">
                          <img
                            src={`${IMG_URI}/${item.image}`}
                            alt={item.title}
                          />
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="project__des">
                          <span>{item.types}</span>
                          <h2>{item.title}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></p>
                          <Link to={`/portfolio/${item.alias}`}>
                            More about This Project
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
          </div>
        </section>
        <section className="services-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="main__title">
                  <span>SERVICES</span>
                  <h2>
                    With comprehensive design services, sound budget management,
                    and timely delivery, we can meet your project needs
                    efficiently and effectively!
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="service__item">
                  <div className="service__icon">
                    <img src="img/service-1.png" alt="Service" />
                  </div>
                  <div className="service__des">
                    <span>1</span>
                    <h4>Architecture</h4>
                    <p className="mb-0">
                      By blending artistry, technology, and unrivalled services,
                      we are continually ushering in a new era of architecture
                      with end to end solutions and innovative designs.
                      <br />
                      Through innovative technology combined with unwavering
                      passion, we turn your vision into a beautiful facade and
                      overcome engineering and technical challenges regardless
                      of the project's scale, complexity, or location.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="service__item">
                  <div className="service__icon">
                    <img src="img/service-1.png" alt="Service" />
                  </div>
                  <div className="service__des">
                    <span>2</span>
                    <h4>Interior Design</h4>
                    <p className="mb-0">
                      What catches great attention in your space is its interior
                      design. We curate eye-catchy interiors by covering a wide
                      range of work namely; art direction, product selection,
                      interior design, custom furniture and fabrication. We
                      focus on the functionality, beauty and charm of custom
                      creations that are necessarily required to adorn the
                      spaces.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="service__item">
                  <div className="service__icon">
                    <img src="img/service-1.png" alt="Service" />
                  </div>
                  <div className="service__des">
                    <span>3</span>
                    <h4>Commercial</h4>
                    <p className="mb-0">
                      With growing times, commercial complexes and offices are
                      also looking forward to having an elegant and luxurious
                      space.
                      <br />
                      We specialise in building project designs that bestow
                      unparalleled exposure in harmony with the geographic
                      location and requirements of the clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="service__item">
                  <div className="service__icon">
                    <img src="img/service-1.png" alt="Service" />
                  </div>
                  <div className="service__des">
                    <span>4</span>
                    <h4>Residential</h4>
                    <p className="mb-0">
                      We possess rich experience in designing and developing
                      unparalleled residential designs that exude flawless
                      architecture and zero wastage of space. We also take care
                      of Vastu principles for our clients to give an enriching
                      experience of residing in their space.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our__work-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="our__work row no-gutters">
                  <Swiper
                    className="our__work__slider col-lg-9"
                    modules={[EffectFade, Navigation]}
                    navigation={{
                      nextEl: ".swiper-button-next.our__work-arrow",
                      prevEl: ".swiper-button-prev.our__work-arrow",
                    }}
                  >
                    <SwiperSlide
                      style={{
                        width: "945px",
                        opacity: 1,
                        transform: "translate3d(0px, 0px, 0px)",
                        transitionDuration: 0,
                      }}
                    >
                      <div className="our__work__banner">
                        <img src="img/work_banner_1.png" alt="Work Banner-1" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="our__work__banner">
                        <img src="img/about__banner2.png" alt="Work Banner-2" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="our__work__text col-lg-3">
                    <p>
                      An exceptional service provided by Mr. Zaid and Team. The
                      best architecture and interior service provider based out
                      of Surat, Gujarat. I have approached the team Zaid Excel
                      Design to provide construction plan , approvals , layout
                      etc. I can surely say that they are the most prominent and
                      professional team and work within timeframe. I had
                      hassle-free interaction with them.
                      <br />
                      <b>- James Macwan</b>
                    </p>
                    <div className="our__work-arrow swiper-button-next"></div>
                    <div className="our__work-arrow swiper-button-prev"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="awards-block">
          <div className="row align-items-center">
            <div className="awards__text-col col-lg-4">
              <div className="main__title">
                <span>AWARDS</span>
                <h2>Our Awards and Achievements</h2>
                <Link to="/awards" className="btn">
                  Know More <i className="far fa-long-arrow-alt-right ml-2"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-8">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                className="award__slider"
                breakpoints={{
                  0: { slidesPerView: 2, spaceBetween: 10, freeMode: true },
                  768: { slidesPerView: 2 },
                  1024: { slidesPerView: 2 },
                }}
              >
                <SwiperSlide>
                  <div className="awards__item">
                    <div className="awards__img">
                      <img src="img/award-1.png" alt="Award-1" />
                    </div>
                    <div className="awards__des">
                      <h4 className="text-white">Architecture</h4>
                      <p className="text-white mb-0">
                        The Best Brand award under Architecture
                      </p>
                      <p className="text-white mb-0">2018</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="awards__item">
                    <div className="awards__img">
                      <img src="img/award-2.png" alt="Award-2" />
                    </div>
                    <div className="awards__des">
                      <h4 className="text-white">600K+ Sq. ft</h4>
                      <p className="text-white mb-0">
                        Creative, Functionality, and Aesthetics are the
                        hallmarks of ZED.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="awards__item">
                    <div className="awards__img">
                      <img src="img/award-1.png" alt="Awards" />
                    </div>
                    <div className="awards__des">
                      <h4 className="text-white">Architecture</h4>
                      <p className="text-white mb-0">The Best Brand award under Architecture</p>
                      <p className="text-white mb-0">2015</p>
                    </div>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </section>
        <section className="blogs-block">
          <BlogContainer />
        </section>
      </Layout>
    );
  }
}
