const URI = process.env.REACT_APP_URI || "https://api.zaidexceldesign.com/frontend/"

module.exports = {
  IMG_URI: "https://zaidexceldesign.com/public",
  GET_PROCESSES: {
    method: "POST",
    url: `${URI}/getProcess`
  },
  CONTACT_US: {
    method: "POST",
    url: `${URI}/contactUs`
  },
  LEADS: {
    method: "POST",
    url: `${URI}/leads`
  },
  GET_POSTS: {
    method: "POST",
    url: `${URI}/getPosts`
  },
  GET_WORKS: {
    method: "POST",
    url: `${URI}/getWorks`
  },
  GET_WORK: {
    method: "POST",
    url: `${URI}/getWork`
  },
  GET_WORKNEXT: {
    method: "POST",
    url: `${URI}/getWorkNext`
  },
  GET_WORKGROUP: {
    method: "POST",
    url: `${URI}/getWorkGroup`
  },
  GET_POST: {
    method: "POST",
    url: `${URI}/getPost`
  },
  GET_POST_CATEGORIES: {
    method: "GET",
    url: `${URI}/getPostCategories`
  },
  GET_POST_IMAGES: {
    method: "GET",
    url: `${URI}/image/post`
  },
  GET_WORK_IMAGES: {
    method: "GET",
    url: `${URI}/image/work`
  },
  GET_COUNTRIES: {
    method: "GET",
    url: `${URI}/getAllCountries`
  },
  GET_STATES: {
    method: "POST",
    url: `${URI}/getAllState`
  }
}